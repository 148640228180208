<template>
  <div>
    <div
      class="text-caption font-weight-medium mb-1"
      :class="hideDividiers ? 'pt-0' : 'mt-n2'"
    >
      Qualifikationen
    </div>
    <v-row class="match-height" dense>
      <v-col>
        <div v-if="!relevantSkills.length" class="mb-1">
          Keine Qualifikationen zugewiesen
        </div>
        <div v-else class="mb-2">
          <v-row dense class="match-height">
            <v-col
              cols="3"
              v-for="(skill, index) in relevantSkills"
              :key="index"
            >
              <v-card outlined height="70px" max-height="70px">
                <div class="d-flex justify-center flex-grow-0">
                  <v-img
                    v-if="skill.badgeUrl.size200x200"
                    contain
                    width="70"
                    height="40"
                    class="ma-1"
                    :src="skill.badgeUrl.size200x200"
                  />
                  <div v-else style="height: 40px !important" class="ma-1">
                    <v-icon
                      :color="skill.group.color"
                      size="30"
                      class="align-center ma-2"
                      >mdi-{{ skill.group.icon }}</v-icon
                    >
                  </div>
                </div>

                <p
                  class="px-1 ma-0 text-center font-weight-medium caption text-truncate"
                  :class="
                    $vuetify.theme.dark
                      ? 'white--text grey darken-2'
                      : 'grey lighten-2'
                  "
                >
                  {{ skill.shortTitle }}
                </p>
              </v-card>
            </v-col>
          </v-row>
        </div>
      </v-col>
    </v-row>
  </div>
</template>

<script>
export default {
  name: "personnel-record-detail-card-skills",
  props: {
    member: {
      type: Object,
      required: true,
    },
  },
  components: {},
  data() {
    return {};
  },
  computed: {
    relevantSkills() {
      return (
        this.member.user?.relevantSkills?.data ||
        this.member?.user.relevantSkills?.data ||
        this.member.relevantSkills?.data
      );
    },
    memberManagementConfig() {
      return this.$store.state.organization.activeOrganization.config
        .memberManagement;
    },
    primaryBadgeUrl() {
      const primarySkillGroupId =
        this.memberManagementConfig.badgeDisplay.primarySkillGroupId || "";
      if (primarySkillGroupId) {
        const skillsOfPrimaryGroup = this.member.relevantSkills.data.filter(
          (skill) => skill.group.id === primarySkillGroupId
        );

        if (skillsOfPrimaryGroup.length === 0) {
          return "";
        }

        const highestPrimarySkill = skillsOfPrimaryGroup.reduce(
          (prev, current) => (prev.sortKey > current.sortKey ? prev : current),
          skillsOfPrimaryGroup[0]
        );

        return highestPrimarySkill.badgeUrl.size200x200;
      } else {
        return "";
      }
    },
  },
  methods: {},
};
</script>
