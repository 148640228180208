<template>
  <div>
    <v-list-item>
      <v-list-item-avatar v-if="!hideDividers" rounded :size="small ? 50 : 75">
        <v-img
          src=""
          :class="$vuetify.theme.dark ? ' grey darken-2' : 'grey lighten-2'"
        ></v-img>
      </v-list-item-avatar>
      <v-list-item-content>
        <v-list-item-title
          class="font-weight-medium"
          :class="hideDividiers ? 'subtitle-2 ' : 'subtitle-1 mb-2'"
        >
          {{
            member.user?.displayName
              ? member.user.displayName
              : member.user?.firstName + " " + member.user?.lastName
          }}
        </v-list-item-title>
        <v-list-item-subtitle>
          <v-chip
            outlined
            label
            v-if="member.user.rank.title"
            class="mr-2"
            :small="small"
          >
            <img
              v-if="member.user?.rank?.badgeUrl?.size125x125"
              :src="member.user?.rank?.badgeUrl?.size125x125"
              contain
              height="20px"
              class="mr-2"
            />
            <span class="font-weight-medium">{{
              member.user.rank.shortTitle
            }}</span>
          </v-chip>
          <v-chip
            outlined
            label
            v-if="member.user.dutyPosition.title"
            :small="small"
          >
            <img
              v-if="member.user?.dutyPosition?.badgeUrl?.size125x125"
              :src="member.user?.dutyPosition?.badgeUrl?.size125x125"
              contain
              height="20px"
              class="mr-2"
            />
            <span class="font-weight-medium">{{
              member.user.dutyPosition.shortTitle
            }}</span>
          </v-chip>
        </v-list-item-subtitle>
      </v-list-item-content>
      <!-- <v-list-item-action v-if="!hideDividers">
        <v-tooltip bottom dense>
          <template v-slot:activator="{ on, attrs }">
            <v-btn
              icon
              text
              small
              color="primary"
              v-bind="attrs"
              v-on="on"
              :to="{
                name: 'member-management-personnel-records-details-general',
                params: {
                  organizationId: $route.params.organizationId,
                  itemId: member.user.uid,
                },
              }"
              class="ml-2"
              ><v-icon small>mdi-open-in-app</v-icon></v-btn
            >
          </template>
          <span>Personalakte öffnen</span>
        </v-tooltip>
      </v-list-item-action> -->
    </v-list-item>
    <v-divider
      v-if="!hideDividers && !hideSkills && checkSkillsAvailability"
    ></v-divider>
    <v-card-text
      v-if="!hideSkills && checkSkillsAvailability"
      class="pb-1"
      :class="hideDividiers ? 'pt-0' : ''"
    >
      <personnel-record-detail-card-skills :member="member" :small="small">
      </personnel-record-detail-card-skills>
    </v-card-text>
    <v-divider
      v-if="!hideDividers && !hideAssignments && checkAssignmentsAvailability"
    ></v-divider>
    <v-card-text
      v-if="!hideAssignments && checkAssignmentsAvailability"
      class="pb-1"
    >
      <personnel-record-detail-card-assignments :member="member" :small="small">
      </personnel-record-detail-card-assignments>
    </v-card-text>
  </div>
</template>

<script>
import PersonnelRecordDetailCardSkills from "@/components/member-management/personnel-records/PersonnelRecordDetailCardSkills.vue";
import PersonnelRecordDetailCardAssignments from "@/components/member-management/personnel-records/PersonnelRecordDetailCardAssignments.vue";
export default {
  name: "personnel-record-detail-card-content",
  props: {
    member: {
      type: Object,
      required: true,
    },
    mode: {
      type: String,
      default: "membershipPublic",
    },
    outlined: {
      type: Boolean,
      default: false,
    },
    hideDividers: {
      type: Boolean,
      required: true,
      default: false,
    },
    flat: {
      type: Boolean,
      default: false,
    },
    hideAssignments: {
      type: Boolean,
      default: false,
    },
    hideSkills: {
      type: Boolean,
      default: false,
    },
    small: {
      type: Boolean,
      default: false,
    },
  },
  components: {
    PersonnelRecordDetailCardAssignments,
    PersonnelRecordDetailCardSkills,
  },
  data() {
    return {
      showAssignments: true,
      showSkills: true,
    };
  },
  computed: {
    memberData() {
      if (this.mode === "membershipPublic") {
        return this.member;
      } else if (this.mode === "personnelRecord") {
        return {
          ...this.member.user,
        };
      } else {
        return this.member;
      }
    },
    checkAssignmentsAvailability() {
      return this.$store.getters["organization/checkExtensionAvailabilityById"](
        "memberManagement.assignments"
      );
    },
    checkSkillsAvailability() {
      return this.$store.getters["organization/checkExtensionAvailabilityById"](
        "memberManagement.skills"
      );
    },
    relevantSkills() {
      return (
        this.member.user?.relevantSkills?.data ||
        this.member?.user.relevantSkills?.data ||
        this.member.relevantSkills?.data
      );
    },
    memberManagementConfig() {
      return this.$store.state.organization.activeOrganization.config
        .memberManagement;
    },
    primaryBadgeUrl() {
      const primarySkillGroupId =
        this.memberManagementConfig.badgeDisplay.primarySkillGroupId || "";
      if (primarySkillGroupId) {
        const skillsOfPrimaryGroup = this.member.relevantSkills.data.filter(
          (skill) => skill.group.id === primarySkillGroupId
        );

        if (skillsOfPrimaryGroup.length === 0) {
          return "";
        }

        const highestPrimarySkill = skillsOfPrimaryGroup.reduce(
          (prev, current) => (prev.sortKey > current.sortKey ? prev : current),
          skillsOfPrimaryGroup[0]
        );

        return highestPrimarySkill.badgeUrl.size200x200;
      } else {
        return "";
      }
    },
  },
  methods: {},
};
</script>
