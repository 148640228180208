<template>
  <div>
    <v-card class="transparent" flat>
      <v-card-title
        v-if="!helpers.dataIsLoading && !hideHeader"
        class="px-0"
        :class="
          $vuetify.breakpoint.smAndDown ? 'subtitle-1 font-weight-medium' : ''
        "
        >{{ title }}<v-spacer></v-spacer>
        <v-btn
          v-if="!hideDetailsBtn"
          text
          outlined
          small
          disabled
          :to="{
            name: 'member-management-performance-targets-overview',
          }"
          >Details anzeigen</v-btn
        >
      </v-card-title>

      <div>
        <v-row class="match-height">
          <v-col cols="12">
            <v-card v-if="!noResult">
              <v-card-text class="pb-0">
                <v-row dense>
                  <v-col cols="6" sm="6" md="6">
                    <v-card outlined>
                      <v-list-item>
                        <v-list-item-avatar
                          :color="totalStatusDisplay.colorLighten"
                        >
                          <v-icon :color="totalStatusDisplay.colorDarken">
                            {{ totalStatusDisplay.icon }}
                          </v-icon>
                        </v-list-item-avatar>
                        <v-list-item-content>
                          <v-list-item-title>{{
                            totalStatusDisplay.text
                          }}</v-list-item-title>
                          <v-list-item-subtitle
                            >Status (Gesamt)</v-list-item-subtitle
                          >
                        </v-list-item-content>
                      </v-list-item>
                    </v-card>
                  </v-col>
                  <v-col cols="6" sm="6" md="6" hidden>
                    <v-card outlined>
                      <v-list-item>
                        <v-list-item-content>
                          <v-list-item-title>DD.MM.YYYY</v-list-item-title>
                          <v-list-item-subtitle
                            >Nächster Cut-Off</v-list-item-subtitle
                          >
                        </v-list-item-content>
                      </v-list-item>
                    </v-card>
                  </v-col>
                  <v-col cols="12">
                    <v-card outlined>
                      <v-simple-table dense>
                        <template v-slot:default>
                          <tbody>
                            <tr class="v-row-group__header">
                              <th class="rounded-tl">Zielvorgabe</th>
                              <th>Wert</th>
                              <th class="rounded-tr">Status</th>
                            </tr>
                            <tr
                              v-for="(
                                performanceTargetResult, index
                              ) in performanceTargetResult.performanceTargets"
                              :key="index"
                            >
                              <td
                                class="font-weight-medium v-row-group__header"
                              >
                                {{
                                  performanceTargetResult.performanceTarget
                                    .title
                                }}
                              </td>
                              <td>{{ performanceTargetResult.value }}</td>
                              <td>
                                <performance-target-status-chip
                                  :status="performanceTargetResult.status"
                                  small
                                  outlined
                                ></performance-target-status-chip>
                              </td>
                            </tr>
                            <!-- <tr
                      v-for="(
                        performanceTargetStatus, index
                      ) in performanceTargetStatus.series"
                      :key="index"
                    >
                      <td>{{ performanceTargetStatus.name }}</td>
                      <td class="text-right">
                        <v-chip small outlined label>
                          {{ performanceTargetStatus.data.length }}
                        </v-chip>
                      </td>
                    </tr> -->
                          </tbody>
                        </template>
                      </v-simple-table>
                    </v-card>
                  </v-col>
                </v-row>
              </v-card-text>
              <v-card-subtitle class="pt-2">
                Einbezogener Betrachtungszeitraum:
                {{
                  timestampConverter(
                    performanceTargetResult.meta.lastCalculatedAt,
                    false
                  )
                }}
                –
                {{
                  timestampConverter(
                    performanceTargetResult.meta.lastCalculatedAt,
                    false
                  )
                }}
                <br />
                Letzte Aktualisierung:
                {{
                  timestampConverter(
                    performanceTargetResult.meta.lastCalculatedAt
                  )
                }}
                Uhr</v-card-subtitle
              >
            </v-card>
            <v-alert
              v-else
              border="left"
              colored-border
              type="error"
              transition="scale-transition"
              elevation="2"
              class="subtitle-2"
            >
              Zum aktuellen Zeitpunkt steht für Dich kein persönliches
              Berechnungsergebnis zum Abruf bereit.
            </v-alert>
          </v-col>
        </v-row>
      </div>
    </v-card>
  </div>
</template>

<script>
import { db, auth } from "@/firebase";
import PerformanceTargetStatusChip from "@/components/member-management/performance-targets/PerformanceTargetStatusChip.vue";
export default {
  name: "self-service-open-duty-reports",
  props: {
    hideHeader: {
      type: Boolean,
      default: false,
    },
    title: {
      type: String,
      default: "Zielvorgaben",
    },
    hideDetailsBtn: {
      type: Boolean,
      default: false,
    },
  },
  components: {
    PerformanceTargetStatusChip,
  },
  data() {
    return {
      helpers: {
        dataIsLoading: false,
      },
      currentUserUid: auth.currentUser.uid,
      noResult: false,
      performanceTargetResult: {},
    };
  },
  computed: {
    totalStatusDisplay() {
      const status =
        this.performanceTargetResult.totalPerformanceTargetResultStatus;
      switch (status) {
        case "met":
          return {
            text: "Erfüllt",
            icon: "mdi-check-circle",
            colorDarken: "green darken-2",
            colorLighten: "green lighten-4",
          };
        case "notMet":
          return {
            text: "Nicht erfüllt",
            icon: "mdi-close",
            colorDarken: "red darken-2",
            colorLighten: "red lighten-4",
          };
        case "notApplicable":
          return {
            text: "Nicht anwendbar",
            icon: "mdi-help",
            colorDarken: "purple darken-2",
            colorLighten: "purple lighten-4",
          };
        case "unknown":
        default:
          return {
            text: "Unbekannt",
            icon: "mdi-help",
            colorDarken: "grey darken-2",
            colorLighten: "grey lighten-4",
          };
      }
    },
  },
  created() {
    this.initialize();
  },
  methods: {
    initialize() {
      // const currentDate = new Date();
      // const customCalculationEndDate = new Date(
      //   currentDate.setHours(23, 59, 59, 999)
      // ).getTime();
      db.collection("organizations")
        .doc(this.$route.params.organizationId)
        .collection("performanceTargetResults")
        // .where("user.uid", "==", this.currentUserUid)
        .where(
          "calculationParameters.customCalculationEndDate",
          "==",
          1741651199999
          // Timestamp.fromMillis(customCalculationEndDate)
        )
        .limit(1)
        .get()
        .then((querySnapshot) => {
          if (!querySnapshot.empty) {
            querySnapshot.forEach((doc) => {
              this.performanceTargetResult = doc.data();
            });
            this.helpers.dataIsLoading = false;
          } else {
            console.log("No such document!");
            this.noResult = true;
          }
        })
        .catch(function (error) {
          console.log("Error getting document:", error);
          this.noResult = true;
          return false;
        });
    },
    timestampConverter(timestamp, includeTime = true) {
      const options = {
        year: "numeric",
        month: "2-digit",
        day: "2-digit",
      };
      if (includeTime) {
        options.hour = "2-digit";
        options.minute = "2-digit";
      }
      return timestamp.toDate().toLocaleDateString("de-DE", options);
    },
  },
};
</script>
