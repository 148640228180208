<template>
  <div>
    <v-card class="transparent" flat>
      <v-card-title
        class="px-0"
        :class="
          $vuetify.breakpoint.smAndDown ? 'subtitle-1 font-weight-medium' : ''
        "
        >Mein Profil<v-spacer></v-spacer>
        <!-- <v-btn
          v-if="!showPersonnelRecordInViewMode"
          text
          outlined
          small
          :disabled="helpers.dataIsLoading"
          :to="{
            name: 'member-management-personnel-records-details-general',
            params: {
              organizationId: $route.params.organizationId,
              itemId: currentUserUid,
            },
          }"
          >Personalakte anzeigen</v-btn
        > -->
      </v-card-title>
      <v-skeleton-loader
        v-if="helpers.dataIsLoading"
        elevation="2"
        type="card-heading, list-item-two-line, divider, card-heading, list-item-two-line, divider, card-heading, list-item-two-line"
      ></v-skeleton-loader>
      <div v-else>
        <v-row class="match-height">
          <v-col cols="12">
            <personnel-record-detail-card
              :member="member"
              mode="personnelRecord"
            ></personnel-record-detail-card>
          </v-col>
        </v-row>
      </div>
    </v-card>
  </div>
</template>

<script>
import PersonnelRecordDetailCard from "@/components/member-management/personnel-records/PersonnelRecordDetailCard.vue";
import { auth } from "@/firebase";
export default {
  name: "self-service-personnel-record",
  props: {
    showPersonnelRecordInViewMode: {
      type: Boolean,
      default: false,
      required: true,
    },
  },
  components: { PersonnelRecordDetailCard },
  data() {
    return {
      helpers: {
        dataIsLoading: false,
      },
      user: {
        id: null,
        salutation: null,
        letterSalutation: null,
        title: null,
        firstName: null,
        lastName: null,
        birthday: null,
        birthdate: null,
        photoUrl: null,

        memberSince: null,
        memberUntil: null,
        dutyPosition: {
          badgeUrl: {
            size70x70: null,
            size125x125: null,
          },
          title: null,
          since: null,
        },
        rank: {
          badgeUrl: {
            size70x70: null,
            size125x125: null,
          },
          title: null,
          since: null,
        },
        status: null,
        division: null,
      },
      currentUserUid: auth.currentUser.uid,
    };
  },
  computed: {
    member() {
      // if (this.organization == null || {}) {
      //   return [];
      // } else {
      return this.$store.state.organization.membership;
      // }
    },
  },
  created() {
    this.initialize();
  },
  methods: {
    initialize() {
      // db.collection("organizations")
      //   .doc(this.$route.params.organizationId)
      //   .collection("personnelRecords_general")
      //   .doc(this.currentUserUid)
      //   .get()
      //   .then((doc) => {
      //     if (doc.exists) {
      //       this.user = doc.data();
      //       this.helpers.dataIsLoading = false;
      //     } else {
      //       // doc.data() will be undefined in this case
      //       console.log("No such document!");
      //     }
      //   })
      //   .catch(function (error) {
      //     console.log("Error getting document:", error);
      //     return false;
      //   });
    },
    timestampConverter(timestamp) {
      const options = {
        weekday: "short",
        year: "numeric",
        month: "2-digit",
        day: "2-digit",
        hour: "2-digit",
        minute: "2-digit",
      };
      return timestamp.toDate().toLocaleDateString("de-DE", options);
    },
  },
};
</script>
