<template>
  <div>
    <v-card class="transparent" flat>
      <v-card-title
        class="px-0"
        :class="
          $vuetify.breakpoint.smAndDown ? 'subtitle-1 font-weight-medium' : ''
        "
        >Meine Fristen<v-spacer></v-spacer>
        <v-btn text outlined small class="mr-2" disabled
          ><v-icon left small>mdi-timer-edit</v-icon>Frist aktualisieren</v-btn
        >
        <!-- :disabled="helpers.dataIsLoading" -->
      </v-card-title>

      <div>
        <v-skeleton-loader
          v-if="helpers.dataIsLoading"
          elevation="2"
          type="card-heading, list-item-two-line, divider, card-heading, list-item-two-line, divider, card-heading, list-item-two-line"
        ></v-skeleton-loader>
        <v-card v-else>
          <v-data-iterator
            :items="deadlinesCacheWithStatus.entries"
            no-data-text="Keine Fristeneinträge vorhanden"
            disable-pagination
            hide-default-footer
          >
            <template v-slot:default="props">
              <v-row no-gutters>
                <v-col
                  v-for="(deadlineEntry, index) in props.items"
                  :key="index"
                  cols="12"
                >
                  <v-list-item>
                    <v-list-item-content>
                      <v-list-item-title class="font-weight-medium">{{
                        deadlineEntry.deadline.title
                      }}</v-list-item-title>
                      <v-list-item-subtitle>
                        <span v-if="deadlineEntry.status === 'valid'">{{
                          deadlineEntry.dueDate
                        }}</span>
                        <span
                          v-else-if="deadlineEntry.status === 'soonDue'"
                          class="orange--text font-weight-medium"
                          >Läuft in Kürze ab: {{ deadlineEntry.dueDate }}</span
                        >
                        <span
                          v-else-if="deadlineEntry.status === 'expired'"
                          class="red--text font-weight-medium"
                          >Abgelaufen am: {{ deadlineEntry.dueDate }}</span
                        >
                        <br />
                        <v-chip
                          small
                          outlined
                          v-for="(label, index) in deadlineEntry.labels"
                          :key="index"
                          class="py-0 mb-0 mt-1 mr-1"
                        >
                          {{ label }}</v-chip
                        >
                      </v-list-item-subtitle>
                    </v-list-item-content>

                    <v-list-item-action>
                      <v-icon
                        v-if="deadlineEntry.status === 'valid'"
                        color="green"
                        >mdi-check</v-icon
                      >
                      <v-icon
                        v-else-if="deadlineEntry.status === 'soonDue'"
                        color="orange"
                        >mdi-timer-sand</v-icon
                      >
                      <v-icon
                        v-else-if="deadlineEntry.status === 'expired'"
                        color="red"
                        >mdi-close</v-icon
                      >
                    </v-list-item-action>
                    <!-- <v-list-item-action>
                      <v-btn large text outlined>
                        <v-icon>mdi-timer-edit-outline</v-icon>
                      </v-btn>
                    </v-list-item-action> -->
                  </v-list-item>
                  <v-divider v-if="props.items.length != index + 1"></v-divider>
                </v-col>
              </v-row>
            </template>
          </v-data-iterator>
        </v-card>
      </div>
    </v-card>
  </div>
</template>

<script>
import { db, auth } from "@/firebase";
// import { DUTY_MANAGEMENT } from "@/store/modules.js";
// import { GET_REPORTS } from "@/store/action-types.js";
// import DutyTypeChip from "@/components/duty-management/DutyTypeChip.vue";
// import ReportStatusChip from "@/components/duty-management/ReportStatusChip.vue";
export default {
  name: "self-service-deadlines",
  props: {},
  components: {
    // DutyTypeChip,
    // ReportStatusChip,
  },
  data() {
    return {
      helpers: {
        dataIsLoading: true,
      },
      deadlinesCache: {
        entries: [],
      },
      currentUserUid: auth.currentUser.uid,
    };
  },
  computed: {
    deadlinesCacheWithStatus() {
      return {
        ...this.deadlinesCache,
        entries: this.deadlinesCache.entries.map((entry) => {
          const dueDate = new Date(entry.deadline.dueDate);
          const today = new Date();
          const diffTime = dueDate - today;
          const diffDays = Math.ceil(diffTime / (1000 * 60 * 60 * 24));
          if (diffDays < 0) {
            return {
              ...entry,
              status: "expired",
            };
          } else if (diffDays <= 30) {
            return {
              ...entry,
              status: "soonDue",
            };
          } else {
            return {
              ...entry,
              status: "valid",
            };
          }
        }),
      };
    },
  },
  created() {
    this.initialize();
  },
  methods: {
    initialize() {
      db.collection("organizations")
        .doc(this.$route.params.organizationId)
        .collection("deadlinesCache")
        .doc(this.currentUserUid)
        .get()
        .then((doc) => {
          if (doc.exists) {
            this.deadlinesCache = doc.data();
            this.helpers.dataIsLoading = false;
          } else {
            // doc.data() will be undefined in this case
            console.log("No such document!");
          }
        })
        .catch(function (error) {
          console.log("Error getting document:", error);
          return false;
        });
    },
    timestampConverter(timestamp) {
      const options = {
        year: "numeric",
        month: "2-digit",
        day: "2-digit",
      };
      return timestamp.toDate().toLocaleDateString("de-DE", options);
    },
  },
};
</script>
