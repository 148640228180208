<template>
  <div>
    <NavigationBar titel="Self-Service"></NavigationBar>
    <section>
      <v-container fluid>
        <self-service-quick-actions-card
          :noActionsAvailable="noActionsAvailable"
        ></self-service-quick-actions-card>
        <v-row>
          <TippCard
            hintID="SelfService_Allgemein_1_V1"
            text="Im Self-Service lassen sich verschiedene Aufgaben erledigen, wie z.B. Abwesenheiten melden, Stunden erfassen oder Leistungsziele einsehen. Gleichzeitig kann der Status offener Vorgänge eingesehen werden."
          >
          </TippCard>
          <v-col cols="12" v-if="noActionsAvailable">
            <v-card>
              <v-card-text class="text-center">
                <v-icon large color="grey lighten-1"
                  >mdi-information-outline</v-icon
                >
                <p class="text-caption mb-0">
                  Für dieses Konto sind keine Aktionen freigegeben.
                </p>
              </v-card-text>
            </v-card>
          </v-col>
          <v-col
            cols="12"
            sm="12"
            md="6"
            lg="4"
            v-if="showPersonnelRecord || showPersonnelRecordInViewMode"
          >
            <self-service-personnel-record
              :showPersonnelRecordInViewMode="showPersonnelRecordInViewMode"
            ></self-service-personnel-record>
          </v-col>
          <v-col cols="12" sm="12" md="6" lg="4" v-if="showDeadlines">
            <self-service-deadlines></self-service-deadlines>
          </v-col>
          <v-col
            cols="12"
            sm="12"
            md="6"
            lg="4"
            v-if="showPerformanceMonitoring"
          >
            <self-service-performance-monitoring></self-service-performance-monitoring>
          </v-col>
          <v-col cols="12" v-if="showLeaves">
            <self-service-open-leaves></self-service-open-leaves>
          </v-col>
          <v-col cols="12" v-if="showDutyReports">
            <self-service-open-duty-reports></self-service-open-duty-reports>
          </v-col>
          <v-col cols="12" v-if="showTimeSheets">
            <self-service-open-time-sheets></self-service-open-time-sheets>
          </v-col>
          <v-col cols="12" v-if="showExpenses">
            <self-service-open-expenses></self-service-open-expenses>
          </v-col>
          <v-col cols="12" v-if="showPayslips">
            <self-service-open-payslips></self-service-open-payslips>
          </v-col>
        </v-row>
      </v-container>
    </section>
  </div>
</template>

<script>
import NavigationBar from "@/components/_systemwide/NavigationBar.vue";
import TippCard from "@/components/_systemwide/Tipp_Card.vue";
import SelfServiceQuickActionsCard from "@/components/self-service/SelfServiceQuickActionsCard.vue";
import SelfServicePersonnelRecord from "@/components/self-service/SelfServicePersonnelRecord.vue";
import SelfServiceDeadlines from "@/components/self-service/SelfServiceDeadlines.vue";
import SelfServicePerformanceMonitoring from "@/components/self-service/SelfServicePerformanceMonitoring.vue";
import SelfServiceOpenLeaves from "@/components/self-service/SelfServiceOpenLeaves.vue";
import SelfServiceOpenDutyReports from "@/components/self-service/SelfServiceOpenDutyReports.vue";
import SelfServiceOpenTimeSheets from "@/components/self-service/SelfServiceOpenTimeSheets.vue";
import SelfServiceOpenExpenses from "@/components/self-service/SelfServiceOpenExpenses.vue";
import SelfServiceOpenPayslips from "@/components/self-service/SelfServiceOpenPayslips.vue";
import {
  MEMBER_MANAGEMENT_DEADLINES_OWN_read,
  MEMBER_MANAGEMENT_PERFORMANCE_TARGETS_OWN_read,
  MEMBER_MANAGEMENT_PERSONNEL_RECORDS_OWN_read,
  AVAILABILITY_LEAVES_read,
  DUTY_MANAGEMENT_REPORTS_read,
  ACCOUNTING_TIME_SHEETS_read,
  FINANCE_EXPENSES_read,
  ACCOUNTING_PAYSLIPS_read,
} from "@/data/permission-types.js";
export default {
  name: "self-service-portal",
  components: {
    NavigationBar,
    TippCard,
    SelfServiceQuickActionsCard,
    SelfServicePersonnelRecord,
    SelfServiceDeadlines,
    SelfServicePerformanceMonitoring,
    SelfServiceOpenLeaves,
    SelfServiceOpenDutyReports,
    SelfServiceOpenTimeSheets,
    SelfServiceOpenExpenses,
    SelfServiceOpenPayslips,
  },
  data() {
    return {};
  },
  computed: {
    showPersonnelRecord() {
      return (
        this.$store.getters["organization/checkExtensionAvailabilityById"](
          "memberManagement.personnelRecords"
        ) &&
        this.$store.getters["organization/checkPermissionByID"](
          `${MEMBER_MANAGEMENT_PERSONNEL_RECORDS_OWN_read}`
        )
      );
    },
    showPersonnelRecordInViewMode() {
      return this.$store.getters["organization/checkFeatureAvailabilityById"](
        "showPersonnelRecordInViewMode"
      );
    },
    showDeadlines() {
      return (
        this.$store.getters["organization/checkExtensionAvailabilityById"](
          "memberManagement.deadlineMonitoring"
        ) &&
        this.$store.getters["organization/checkPermissionByID"](
          `${MEMBER_MANAGEMENT_DEADLINES_OWN_read}`
        )
      );
    },
    showPerformanceMonitoring() {
      return (
        this.$store.getters["organization/checkExtensionAvailabilityById"](
          "memberManagement.performanceTargets"
        ) &&
        this.$store.getters["organization/checkPermissionByID"](
          `${MEMBER_MANAGEMENT_PERFORMANCE_TARGETS_OWN_read}`
        )
      );
    },
    noActionsAvailable() {
      return (
        !this.showPerformanceMonitoring &&
        !this.showLeaves &&
        !this.showDutyReports &&
        !this.showTimeSheets &&
        !this.showExpenses &&
        !this.showPayslips
      );
    },
    showLeaves() {
      return (
        this.$store.getters["organization/checkExtensionAvailabilityById"](
          "availability.leaves"
        ) &&
        this.$store.getters["organization/checkPermissionByID"](
          `${AVAILABILITY_LEAVES_read}`
        )
      );
    },
    showDutyReports() {
      return (
        this.$store.getters["organization/checkExtensionAvailabilityById"](
          "dutyManagement.reports"
        ) &&
        this.$store.getters["organization/checkPermissionByID"](
          `${DUTY_MANAGEMENT_REPORTS_read}`
        )
      );
    },
    showTimeSheets() {
      return (
        this.$store.getters["organization/checkExtensionAvailabilityById"](
          "availability.leaves"
        ) &&
        this.$store.getters["organization/checkPermissionByID"](
          `${ACCOUNTING_TIME_SHEETS_read}`
        )
      );
    },
    showExpenses() {
      return (
        this.$store.getters["organization/checkExtensionAvailabilityById"](
          "finance.expenses"
        ) &&
        this.$store.getters["organization/checkPermissionByID"](
          `${FINANCE_EXPENSES_read}`
        )
      );
    },
    showPayslips() {
      return (
        this.$store.getters["organization/checkExtensionAvailabilityById"](
          "accounting.payslips"
        ) &&
        this.$store.getters["organization/checkPermissionByID"](
          `${ACCOUNTING_PAYSLIPS_read}`
        )
      );
    },
  },
};
</script>
