<template>
  <div>
    <v-card :outlined="outlined" :flat="flat">
      <personnel-record-detail-card-content
        :member="member"
        :small="small"
        :hideDividers="hideDividers"
        :hideAssignments="hideAssignments"
        :hideSkills="hideSkills"
      >
      </personnel-record-detail-card-content>
    </v-card>
  </div>
</template>

<script>
import PersonnelRecordDetailCardContent from "@/components/member-management/personnel-records/PersonnelRecordDetailCardContent.vue";
export default {
  name: "personnel-record-detail-card",
  props: {
    member: {
      type: Object,
      required: true,
    },
    outlined: {
      type: Boolean,
      default: false,
    },
    hideDividers: {
      type: Boolean,
      default: false,
    },
    flat: {
      type: Boolean,
      default: false,
    },
    hideAssignments: {
      type: Boolean,
      default: false,
    },
    hideSkills: {
      type: Boolean,
      default: false,
    },
    small: {
      type: Boolean,
      default: false,
    },
  },
  components: {
    PersonnelRecordDetailCardContent,
  },
  data() {
    return {
      showAssignments: true,
      showSkills: true,
    };
  },
  computed: {
    checkAssignmentsAvailability() {
      return this.$store.getters["organization/checkExtensionAvailabilityById"](
        "memberManagement.assignments"
      );
    },
    checkSkillsAvailability() {
      return this.$store.getters["organization/checkExtensionAvailabilityById"](
        "memberManagement.skills"
      );
    },
  },
  methods: {},
};
</script>
