<template>
  <data-chip
    :text="statusText(status)"
    :color="statusColor(status)"
    :iconLeft="statusIcon(status)"
    :small="small"
  >
  </data-chip>
</template>

<script>
import DataChip from "@/components/_systemwide/DataChip.vue";
export default {
  name: "reports-status-chip",
  components: {
    DataChip,
  },
  props: {
    status: {
      type: String,
      required: true,
    },
    small: {
      type: Boolean,
      default: false,
    },
  },
  methods: {
    statusText(statusId) {
      switch (statusId) {
        case "met":
          return "Erfüllt";
        case "notMet":
          return "Nicht erfüllt";
        case "metByIndividualRuling":
          return "Erfüllt (EA)";
        case "notMetByIndividualRuling":
          return "Nicht erfüllt (EA)";
        case "fullySuspended":
          return "Vollständig ausgesetzt";
        case "notStarted":
          return "Nicht begonnen";
        case "temporarilySuspended":
          return "Zeitweise ausgesetzt";
        case "notApplicable":
          return "Nicht anwendbar";
        case "unknown":
          return "Unbekannt";
        default:
          return "Fehler";
      }
    },
    statusIcon(statusId) {
      switch (statusId) {
        case "met":
          return "check";
        case "notMet":
          return "close";
        default:
          return "alert";
      }
    },
    statusColor(statusId) {
      switch (statusId) {
        case "met":
          return "green";
        case "notMet":
          return "red";
        case "fullySuspended":
        case "temporarilySuspended":
        case "notStarted":
        case "notApplicable":
        case "unknown":
          return "grey";
        case "individualRuling":
          return "purple";
        default:
          return "grey";
      }
    },
  },
};
</script>
