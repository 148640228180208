<template>
  <div>
    <div class="text-caption font-weight-medium mt-n2 mb-1">
      Aufgabenbereiche
    </div>
    <div v-if="!member.assignments?.ids?.length" class="mb-2">
      Keine Aufgabenbereiche zugewiesen
    </div>
    <data-chip
      v-else
      v-for="(assignment, index) in member.assignments?.data"
      :key="index"
      :color="assignment.color"
      :iconLeft="assignment.icon"
      :text="assignment.title"
      class="mr-2 mb-2"
      small
    >
    </data-chip>
  </div>
</template>

<script>
import DataChip from "@/components/_systemwide/DataChip.vue";
export default {
  name: "personnel-record-detail-card-assignments",
  props: {
    member: {
      type: Object,
      required: true,
    },
    small: {
      type: Boolean,
      default: false,
    },
  },
  components: { DataChip },
  data() {
    return {
      showAssignments: true,
      showSkills: true,
    };
  },
  computed: {
    relevantSkills() {
      return (
        this.member.user?.relevantSkills?.data ||
        this.member?.user.relevantSkills?.data ||
        this.member.relevantSkills?.data
      );
    },
  },
  methods: {},
};
</script>
