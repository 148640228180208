<template>
  <div>
    <v-card
      :to="{
        name: 'accounting-payslips-details',
        params: { itemId: payslip.meta.id },
      }"
    >
      <v-card-title class="subtitle-1 font-weight-medium mt-1 mb-1">
        <span>{{ payslip.batch.title }}</span>
        <v-spacer></v-spacer>
        <payslip-status-chip :status="payslip.status" small>
        </payslip-status-chip>
      </v-card-title>
      <v-card-subtitle class="font-weight-regular">
        <p style="line-height: 1.6" class="mb-0">
          <pre class="font-weight-medium"
            ><v-icon small left>mdi-sigma</v-icon>{{ payslip.summary.totalCompensation.amount }} {{ payslip.summary.totalCompensation.currency }}</pre
          >
          <v-icon small left>mdi-cash-sync</v-icon>
          {{ getPayrollTypeText(payslip.type) }}<br />
          <v-icon small left>mdi-cash-clock</v-icon
          ><span v-if="payslip.paidAt">
            Ausgezahlt am
            {{ timestampConverter(payslip.paidAt) }}</span
          >
          <span v-else>Auszahlung angewiesen am {{ timestampConverter(payslip.instructedPaymentAt) }}</span>
        </p>
      </v-card-subtitle>
    </v-card>
  </div>
</template>

<script>
import PayslipStatusChip from "@/components/accounting/payslips/PayslipStatusChip.vue";
export default {
  name: "payslip-card",
  props: {
    payslip: {
      type: Object,
      required: true,
    },
  },
  components: {
    PayslipStatusChip,
  },
  data() {
    return {};
  },

  methods: {
    timestampConverter(timestamp) {
      const options = {
        weekday: "short",
        year: "numeric",
        month: "2-digit",
        day: "2-digit",
        hour: "2-digit",
        minute: "2-digit",
      };
      return timestamp.toDate().toLocaleDateString("de-DE", options);
    },
    getPayrollTypeText(type) {
      switch (type) {
        case "regular":
          return "Regulärer Turnus";
        case "special":
          return "Sonderzahlung";
        default:
          return "Unbekannt";
      }
    },
  },
};
</script>
